@import "@/styles/common";

.heading {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: get-xd-spacing-size($spacing-size: 24px, $font-size: 18px + 14px, $line-height: 24px + 21px);

  @include media-breakpoint-down(sm) {
    margin-bottom: get-xd-spacing-size($spacing-size: 24px, $font-size: 16px + 12px, $line-height: 24px + 21px);
  }
}

.title {
  padding-top: get-xd-spacing-size($spacing-size: 24px, $font-size: 18px, $line-height: 24px);
  font-size: 18px;
  line-height: 24px;
  font-weight: $font-weight-semiBold;

  @include media-breakpoint-down(sm) {
    padding-top: get-xd-spacing-size($spacing-size: 24px, $font-size: 16px, $line-height: 24px);
    font-size: 16px;
  }
}

.closeButton {
  position: absolute;
  top: -4.1px;
  right: -14.8px;

  @include media-breakpoint-down(sm) {
    top: -.1px;
    right: -10.8px;
  }
}

.body {
  margin-bottom: get-xd-spacing-size($spacing-size: 24px, $font-size: 14px, $line-height: 21px);
}

.text {
  font-size: 14px;
  line-height: 21px;
  color: $gray37;
  margin-bottom: get-xd-spacing-size($spacing-size: 24px, $font-size: 14px, $line-height: 21px);

  @include media-breakpoint-down(sm) {
    font-size: 12px;
    margin-bottom: get-xd-spacing-size($spacing-size: 24px, $font-size: 12px, $line-height: 21px);
  }
}
